import React from 'react';
import logo from './logo.svg';
import './App.css';
import UnSubscribeTemplate from './components/unsubscribe-template/unsubscribe-tem';

function App() {
  return (
    <>
      <UnSubscribeTemplate />
    </>
  );
}

export default App;
