import "../../asstes/styled/unsubscribe.css"
import * as yup from 'yup'
import { Form, Field, Formik, ErrorMessage } from 'formik'
import { MdUnsubscribe } from "react-icons/md";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import Swal from 'sweetalert2'

const UnSubscribeTemplate = () => {
    const siteKeyForClientSite = "6LfZ8K0pAAAAAPnFfcurzqORJIdDJcbqU_e4yd3O"

    const defaultValues = {
        phoneNumber: '',
        captchaValue: ''
    }

    const UserValidateSchema = yup.object().shape({
        phoneNumber: yup.string().matches(RegExp(/^\+?(44)\d{10,13}$/), {message: 'Phone number must match pattern 447570138000'}),
        captchaValue: yup.string().required()
    })

    const handleSubmit = async (values: any) => {
        try {
            Swal.fire({
                title: "Are you sure?",
                text: "Would you like to unsubscribe from our service?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#82b74b",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Unsubscribe!"
            }).then(async (result) => {
                if (result.isConfirmed) {
                    axios.post('https://api.chefsynk.co.uk/data/smsmanagement/unsubscribe', values).then(async () => {
                        await Swal.fire({
                            title: "Unsubscribed!",
                            text: "Successfully.",
                            icon: "success"
                        });
                    }).catch(async () => {
                        await Swal.fire({
                            title: "Unsubscribe!",
                            text: "Sorry something went wrong please try later or contact your local store.",
                            icon: "error"
                        });
                    });
                }
            });
        } catch (error) {
            console.error('Error making POST request:', error);
        }
    }

    return (
        <>
            <Formik initialValues={defaultValues} onSubmit={handleSubmit} validationSchema={UserValidateSchema} >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className='main-container'>
                            <div className="unsubscribe-container">
                                <div className='subscribe_icon'>     <MdUnsubscribe /></div>
                                <div className='unsubscribe-heading'>Unsubscribe</div>
                                <div className='unsubscribe-description'>Please enter your number<br />e.g. 447570138000</div>



                                <Field name="phoneNumber" className='input_element' placeholder="Enter your mobile number" type="text" />

                                <div className="errorMessage">
                                    <ErrorMessage name="phoneNumber" />
                                </div>

                                <div className='recaptcha_box'>
                                    <ReCAPTCHA
                                        sitekey={siteKeyForClientSite}
                                        onChange={(e: any) => {
                                            setFieldValue('captchaValue', e).then(()=> {})
                                        }}
                                    />
                                </div>
                                <button type='submit' className='unsubscribe-button'>Unsubscribe</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default UnSubscribeTemplate
